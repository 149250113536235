import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import baseTheme from '../../themes/baseTheme';
import { history } from '../store/configureStore.prod';
import { ToastProvider } from '../../providers/ToastProvider';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import {afterKClogin} from "../actions/async";
import config from '../../config';

export default class Root extends Component {
  render() {
    const { store, routing,api } = this.props;
    const { protocol, host } = window.location;
    const domain = `${protocol}//${host}`.replace('portal.', `${config.STENO_KEYCLOAK_HOST_PREFIX}.`)

    const keycloak = new Keycloak({
      url: `${domain}/auth`,
      realm: 'steno',
      clientId: 'blip-app'
    });

    const initOptions = { pkceMethod: 'S256',onLoad: 'login-required' };

    const handleOnEvent = async (event, error) => {
      console.log('onHandle KC ',event,error);
    };

    const onKeycloakTokens = async (tokens) => {
      console.log('onKeycloakTokens', tokens);
      api.keycloak=keycloak;
      var sub=null;
      var isPatient=false;
      var isClinician=false;

      if(tokens.token !== null) {
        var base64Url = tokens.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var json = JSON.parse(jsonPayload);
        //console.log('Json '+json);
        sub=json.sub;
        console.log('sub '+sub);

        var roles=json.realm_access.roles;

        isPatient=roles.includes("patient");

        isClinician=roles.includes("clinician");


      }


      //Patient //https://blip.steno.tdpl.dk/patients/e4399ecd-986e-46fe-ae4e-c1d769aad1b7/data
      //https://blip.steno.tdpl.dk/patients
      if(!api.tidepool.isLoggedIn() && tokens.token !== null) {
        var forward = '/patients/'+sub+'/data';
        if(isClinician) {
          forward ='/patients';
        }

        console.log('logging in ');
        //push('/patients?justLoggedIn=true');
        api.tidepool.saveSession(sub,tokens.token,null);
        store.dispatch(afterKClogin(api,forward));

      }
      api.tidepool.saveSession(sub,tokens.token,null);


      //console.log('This api '+ );
      //this.props.api.syncToken(tokens.token);
    };


    return (

      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        onEvent={(event, error) => handleOnEvent(event, error)}
        onTokens={(tokens) => onKeycloakTokens(tokens)}
      >
        <ThemeProvider theme={baseTheme}>
          <ToastProvider>
            <Provider store={store}>
              <div>
                <ConnectedRouter history={history}>
                  {routing}
                </ConnectedRouter>
              </div>
            </Provider>
          </ToastProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>


    );
  }
};
