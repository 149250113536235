exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css"), undefined);

// module
exports.push([module.id, ".StatTooltip--row--24q-P {\n  line-height: 20px;\n  max-width: 180px;\n  min-width: 160px;\n}\n\n.StatTooltip--message--jiDN8 {\n  color: var(--stat--default);\n  color: var(--stat--default);\n}\n\n.StatTooltip--message--jiDN8 p {\n  margin: 0;\n}\n\n.StatTooltip--message--jiDN8 a {\n  pointer-events: all;\n}\n\n.StatTooltip--divider--1LZxP {\n  height: 2px;\n  margin: 5px -10px;\n  background-color: var(--stat--default);\n  background-color: var(--stat--default);\n}\n", "", {"version":3,"sources":["/home/runner/work/viz/viz/src/components/common/tooltips/StatTooltip.css"],"names":[],"mappings":"AAAA;EAEE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;CAClB;;AAED;EAEE,4BAA4B;EAA5B,4BAA4B;CAC7B;;AAED;EACE,UAAU;CACX;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EAAvC,uCAAuC;CACxC","file":"StatTooltip.css","sourcesContent":[".row {\n  composes: smallSize from '../../../styles/typography.css';\n  line-height: 20px;\n  max-width: 180px;\n  min-width: 160px;\n}\n\n.message {\n  composes: row;\n  color: var(--stat--default);\n}\n\n.message p {\n  margin: 0;\n}\n\n.message a {\n  pointer-events: all;\n}\n\n.divider {\n  height: 2px;\n  margin: 5px -10px;\n  background-color: var(--stat--default);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"row": "StatTooltip--row--24q-P " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + "",
	"message": "StatTooltip--message--jiDN8 StatTooltip--row--24q-P " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + "",
	"divider": "StatTooltip--divider--1LZxP"
};