const html2canvas = require('html2canvas');

const copyScreenshot = async (element) => {
  return html2canvas(element)
    .then(canvas => {
      return new Promise((resolve, reject) => {
        const handleBlob = (blob) => {
          navigator.clipboard.write([
            new ClipboardItem({
              ['image/png']: blob
            })
          ]).then(() => {
            canvas.remove();
            resolve()
          }).catch(reject);
        };

        canvas.toBlob(handleBlob);
      })
    });
}

module.exports = copyScreenshot;
