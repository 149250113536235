exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css"), undefined);

// module
exports.push([module.id, ".StatLegend--StatLegend--1TX4s {\n  position: relative;\n  list-style: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n}\n\n.StatLegend--StatLegendItem--2cNhf {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  margin: 0 1em 0 0;\n  border-bottom: 2px solid;\n}\n\n.StatLegend--StatLegendTitle--3OsET {\n  letter-spacing: -.01em;\n  white-space: nowrap;\n  line-height: 16px;\n}\n", "", {"version":3,"sources":["/home/runner/work/viz/viz/src/components/common/stat/StatLegend.css"],"names":[],"mappings":"AAAA;EAEE,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,UAAU;EACV,WAAW;CACZ;;AAED;EACE,cAAc;EACd,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;CAC1B;;AAED;EACE,uBAAuB;EACvB,oBAAoB;EACpB,kBAAkB;CACnB","file":"StatLegend.css","sourcesContent":[".StatLegend {\n  composes: smallSize from '../../../styles/typography.css';\n  position: relative;\n  list-style: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n}\n\n.StatLegendItem {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  margin: 0 1em 0 0;\n  border-bottom: 2px solid;\n}\n\n.StatLegendTitle {\n  letter-spacing: -.01em;\n  white-space: nowrap;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"StatLegend": "StatLegend--StatLegend--1TX4s " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + "",
	"StatLegendItem": "StatLegend--StatLegendItem--2cNhf",
	"StatLegendTitle": "StatLegend--StatLegendTitle--3OsET"
};