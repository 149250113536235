const { useState, useEffect } = require('react');
const React = require('react');

const CopyElementToClipboard = ({
  children,
  onClick,
  successText,
  onHover,
  ...props
}) => {
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    let t
    if (success) {
      t = setTimeout(() => setSuccess(false), 1000)
    }
    return () => clearTimeout(t)
  }, [success])

  const handleClick = (e) => {
    if (e) e.preventDefault()
    if (!onClick) return

    onClick(e).then(() => {
      setSuccess(true)
    }).catch(() => {
      alert('There was an error copying the data to the clipboard.')
    })
  }

  return (
    <a
      href=""
      onClick={handleClick}
      // onMouseEnter={() => onHover && onHover(true)}
      // onMouseLeave={() => onHover && onHover(false)}
      {...props}
    >
      {success ? successText : children}
    </a>
  )
}

module.exports = CopyElementToClipboard;
