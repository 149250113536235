exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/colors.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css"), undefined);

// module
exports.push([module.id, ".PumpSettingsOverrideTooltip--container--3sGYV {\n   opacity: 1;\n   display: flex;\n   flex-direction: column;\n   min-width: 80px;\n   margin: 2px 0;\n   max-width: 180px;\n }\n\n .PumpSettingsOverrideTooltip--row--31U4k {\n   display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n   line-height: 20px;\n }\n\n .PumpSettingsOverrideTooltip--label--3jNB4 {\n   flex-grow: 1;\n }\n\n .PumpSettingsOverrideTooltip--value--3KpnG {\n   margin-left: 20px;\n }\n\n .PumpSettingsOverrideTooltip--title--2tfwy {\n   text-align: center;\n }\n\n .PumpSettingsOverrideTooltip--overrideType--TLxUF {\n   font-weight: normal;\n }\n\n .PumpSettingsOverrideTooltip--overrideType--TLxUF div:last-child {\n   font-weight: bold;\n }\n", "", {"version":3,"sources":["/home/runner/work/viz/viz/src/components/daily/pumpsettingsoverridetooltip/PumpSettingsOverrideTooltip.css"],"names":[],"mappings":"AAEC;GACE,WAAW;GACX,cAAc;GACd,uBAAuB;GACvB,gBAAgB;GAChB,cAAc;GACd,iBAAiB;EAClB;;CAED;GAEE,cAAc;GACd,oBAAoB;GACpB,+BAA+B;GAC/B,kBAAkB;EACnB;;CAED;GACE,aAAa;EACd;;CAED;GACE,kBAAkB;EACnB;;CAED;GAEE,mBAAmB;EACpB;;CAED;GAEE,oBAAoB;EACrB;;CAED;GACE,kBAAkB;EACnB","file":"PumpSettingsOverrideTooltip.css","sourcesContent":[" @import '../../../styles/colors.css';\n\n .container {\n   opacity: 1;\n   display: flex;\n   flex-direction: column;\n   min-width: 80px;\n   margin: 2px 0;\n   max-width: 180px;\n }\n\n .row {\n   composes: smallSize from '../../../styles/typography.css';\n   display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n   line-height: 20px;\n }\n\n .label {\n   flex-grow: 1;\n }\n\n .value {\n   margin-left: 20px;\n }\n\n .title {\n   composes: smallSize from '../../../styles/typography.css';\n   text-align: center;\n }\n\n .overrideType {\n   composes: row;\n   font-weight: normal;\n }\n\n .overrideType div:last-child {\n   font-weight: bold;\n }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "PumpSettingsOverrideTooltip--container--3sGYV",
	"row": "PumpSettingsOverrideTooltip--row--31U4k " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + "",
	"label": "PumpSettingsOverrideTooltip--label--3jNB4",
	"value": "PumpSettingsOverrideTooltip--value--3KpnG",
	"title": "PumpSettingsOverrideTooltip--title--2tfwy " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + "",
	"overrideType": "PumpSettingsOverrideTooltip--overrideType--TLxUF PumpSettingsOverrideTooltip--row--31U4k " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../../node_modules/postcss-loader/src/index.js??ref--6-2!../../../styles/typography.css").locals["smallSize"] + ""
};